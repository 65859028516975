//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { timeFix } from '@/utils/util'
import { mapState } from 'vuex'
import { PageView } from '@/layouts'
import HeadInfo from '@/components/tools/HeadInfo'
import { Radar } from '@/components'
const DataSet = require('@antv/data-set')

export default {
  name: 'Workplace',
  components: {
    PageView,
    HeadInfo,
    Radar
  },
  data () {
    return {
      timeFix: timeFix(),
      avatar: '',
      user: {},

      projects: [],
      loading: true,
      radarLoading: true,
      activities: [],
      teams: [],

      // data
      axis1Opts: {
        dataKey: 'item',
        line: null,
        tickLine: null,
        grid: {
          lineStyle: {
            lineDash: null
          },
          hideFirstLine: false
        }
      },
      axis2Opts: {
        dataKey: 'score',
        line: null,
        tickLine: null,
        grid: {
          type: 'polygon',
          lineStyle: {
            lineDash: null
          }
        }
      },
      scale: [{
        dataKey: 'score',
        min: 0,
        max: 80
      }],
      axisData: [
        { item: '引用', a: 70, b: 30, c: 40 },
        { item: '口碑', a: 60, b: 70, c: 40 },
        { item: '產量', a: 50, b: 60, c: 40 },
        { item: '貢獻', a: 40, b: 50, c: 40 },
        { item: '熱度', a: 60, b: 70, c: 40 },
        { item: '引用', a: 70, b: 50, c: 40 }
      ],
      radarData: []
    }
  },
  computed: {
    ...mapState({
      nickname: (state) => state.user.nickname,
      welcome: (state) => state.user.welcome
    }),
    userInfo () {
      return this.$store.getters.userInfo
    }
  },
  created () {
    this.user = this.userInfo
    this.avatar = process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + this.userInfo.avatar
  },
  mounted () {
    this.getProjects()
    this.getActivity()
    this.getTeams()
    this.initRadar()
  },
  methods: {
    getProjects () {
      this.projects = [{
        id: 1,
        cover: 'https://gw.alipayobjects.com/zos/rmsportal/WdGqmHpayyMjiEhcKoVE.png',
        title: 'Alipay',
        description: '那是一種內在的東西， 他們到達不了，也無法觸及的',
        status: 1,
        updatedAt: '2018-07-26 00:00:00'
      },
        {
          id: 2,
          cover: 'https://gw.alipayobjects.com/zos/rmsportal/zOsKZmFRdUtvpqCImOVY.png',
          title: 'Angular',
          description: '希望是一個好東西，也許是最好的，好東西是不會消亡的',
          status: 1,
          updatedAt: '2018-07-26 00:00:00'
        },
        {
          id: 3,
          cover: 'https://gw.alipayobjects.com/zos/rmsportal/dURIMkkrRFpPgTuzkwnB.png',
          title: 'Ant Design',
          description: '城鎮中有那麽多的酒館，她卻偏偏走進了我的酒館',
          status: 1,
          updatedAt: '2018-07-26 00:00:00'
        },
        {
          id: 4,
          cover: 'https://gw.alipayobjects.com/zos/rmsportal/sfjbOqnsXXJgNCjCzDBL.png',
          title: 'Snowy',
          description: '那時候我只會想自己想要什麽，從不想自己擁有什麽',
          status: 1,
          updatedAt: '2018-07-26 00:00:00'
        },
        {
          id: 5,
          cover: 'https://gw.alipayobjects.com/zos/rmsportal/siCrBXXhmvTQGWPNLBow.png',
          title: 'Bootstrap',
          description: '凜冬將至',
          status: 1,
          updatedAt: '2018-07-26 00:00:00'
        },
        {
          id: 6,
          cover: 'https://gw.alipayobjects.com/zos/rmsportal/ComBAopevLwENQdKWiIn.png',
          title: 'Vue',
          description: '生命就像一盒巧克力，結果往往出人意料',
          status: 1,
          updatedAt: '2018-07-26 00:00:00'
        }
      ]
      this.loading = false
    },
    getActivity () {
      this.activities = [{
        id: 1,
        user: {
          nickname: '@name',
          avatar: 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png'
        },
        project: {
          name: '白鷺醬油開發組',
          action: '更新',
          event: '番組計劃'
        },
        time: '2018-08-23 14:47:00'
      },
        {
          id: 1,
          user: {
            nickname: '藍莓醬',
            avatar: 'https://gw.alipayobjects.com/zos/rmsportal/jZUIxmJycoymBprLOUbT.png'
          },
          project: {
            name: '白鷺醬油開發組',
            action: '更新',
            event: '番組計劃'
          },
          time: '2018-08-23 09:35:37'
        },
        {
          id: 1,
          user: {
            nickname: '@name',
            avatar: '@image(64x64)'
          },
          project: {
            name: '白鷺醬油開發組',
            action: '創建',
            event: '番組計劃'
          },
          time: '2017-05-27 00:00:00'
        },
        {
          id: 1,
          user: {
            nickname: '曲麗麗',
            avatar: '@image(64x64)'
          },
          project: {
            name: '高逼格設計天團',
            action: '更新',
            event: '六月叠代'
          },
          time: '2018-08-23 14:47:00'
        },
        {
          id: 1,
          user: {
            nickname: '@name',
            avatar: '@image(64x64)'
          },
          project: {
            name: '高逼格設計天團',
            action: 'created',
            event: '六月叠代'
          },
          time: '2018-08-23 14:47:00'
        },
        {
          id: 1,
          user: {
            nickname: '曲麗麗',
            avatar: 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png'
          },
          project: {
            name: '高逼格設計天團',
            action: 'created',
            event: '六月叠代'
          },
          time: '2018-08-23 14:47:00'
        }
      ]
    },
    getTeams () {
      this.teams = [{
        id: 1,
        name: '科學搬磚組',
        avatar: 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png'
      },
        {
          id: 2,
          name: '程序員日常',
          avatar: 'https://gw.alipayobjects.com/zos/rmsportal/cnrhVkzwxjPwAaCfPbdc.png'
        },
        {
          id: 1,
          name: '設計天團',
          avatar: 'https://gw.alipayobjects.com/zos/rmsportal/gaOngJwsRYRaVAuXXcmB.png'
        },
        {
          id: 1,
          name: '中二少女團',
          avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ubnKSIfAJTxIgXOKlciN.png'
        },
        {
          id: 1,
          name: '騙妳學計算機',
          avatar: 'https://gw.alipayobjects.com/zos/rmsportal/WhxKECPNujWoWEFNdnJE.png'
        }
      ]
    },
    initRadar () {
      this.radarLoading = true
      const dv = new DataSet.View().source(
        [{
          item: '引用',
          '個人': 70,
          '團隊': 30,
          '部門': 40
        },
          {
            item: '口碑',
            '個人': 60,
            '團隊': 70,
            '部門': 40
          },
          {
            item: '產量',
            '個人': 50,
            '團隊': 60,
            '部門': 40
          },
          {
            item: '貢獻',
            '個人': 40,
            '團隊': 50,
            '部門': 40
          },
          {
            item: '熱度',
            '個人': 60,
            '團隊': 70,
            '部門': 40
          },
          {
            item: '引用',
            '個人': 70,
            '團隊': 50,
            '部門': 40
          }
        ]
      )
      dv.transform({
        type: 'fold',
        fields: ['個人', '團隊', '部門'],
        key: 'user',
        value: 'score'
      })

      this.radarData = dv.rows
      this.radarLoading = false
    }
  }
}
